<template>
    <v-row>
        <v-col cols="12">
            <v-alert
                v-for="(msg, id) in messages"
                :key="id"
                :type="msg.success ? 'success' : 'error'"
            >{{msg.message}}</v-alert>
        </v-col>

        <v-col cols="12" sm="12" lg="2" >
            <div class="p-1x" 
                style="width: 100%; max-width: 100%; height: 100%; max-height: 100%;">
                <div class="justify-center align-center text-center" >
                    <v-img :src="item.subscription.project.src" />
                    
                    <v-card-title class="justify-center align-center">
                        <span> {{ item.subscription.project.title}} </span>
                    </v-card-title>
                </div>
            </div>
        </v-col>

        <v-col cols="12" sm="12" lg="10">
            <v-row>
                <p><span>ICON </span> <span class="ml-2 primary--text text-uppercase">Critérios de Avaliação </span>
                <span><span class="ml-2 primary--text">S-</span>Sim </span>
                <span><span class="ml-2 primary--text">N-</span>Não </span>
                <span><span class="ml-2 primary--text">S/D-</span>Sem Dados</span >
                <v-btn class="ml-2 "
                    text
                    x-small @click="openProjectDialog">
                    <img
                    height="24"
                    width="24"
                    src="@/assets/i-play.png"
                    />
                    <span class="ml-1">Visualizar Obra</span>
                </v-btn>
                </p>
            </v-row>
            <v-row>
                <v-col cols="12" md="10">
                    <v-row>
                        <template v-for="(review, i) in item.reviews.review">
                            <v-col 
                                :key="review.id"
                                cols="12" sm="6">
                                
                                <v-row no-gutters>
                                    <v-col cols="12" sm="3"><p>Crietério {{ i + 1 }}</p></v-col>
                                    <v-col cols="12" sm="4"><div class="primary--text" v-html="review.criteria.criteria"></div></v-col>
                                    <v-col cols="12" sm="5">
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-checkbox class="ml-1" 
                                                    readonly
                                                    v-model="review.status"
                                                    color="primary"
                                                    :label="review.status"
                                                    :value="review.status"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2">
                    <v-container fill-height fluid class="ma-2">
                        <v-row align="center" justify="center">
                                <v-btn
                                    color="success"
                                    max-width="120"
                                    width="100%"
                                    :disabled="submiting"
                                    @click="submitRating(STATUS_CODE.ACCEPTED, item.reviews.id)">
                                    <span>Validar</span>
                                </v-btn>
                        </v-row>
                        <v-row align="center" justify="center">
                                <v-btn
                                    color="error"
                                    :disabled="submiting"
                                    max-width="120"
                                    width="100%"
                                    @click="submitRating(STATUS_CODE.REJECTED, item.reviews.id)">
                                    <span>Rejeitar</span>
                                </v-btn>
                        </v-row>
                        <v-row align="center" justify="center">
                                <v-btn
                                    color="primary"
                                    max-width="120"
                                    width="100%"
                                    outlined>
                                    <span>Rever</span>
                                </v-btn>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-col>
        <v-dialog 
            v-model="viewProjectDialog"
            max-width="500">

            <v-card class="body pa-4">
                <v-toolbar dense flat color="rgba(0, 0, 0, 0)">
                    
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            icon
                            
                            @click="closeProjectDialog">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <fieldset>
                        <legend>
                                Visualizar Obra "<span class="primary--text">{{ item.subscription.project.title }}</span>"
                        </legend>

                        <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                                <v-btn color="success">Link da Obra</v-btn>
                            </v-row>
                            
                            <v-row class="mt-6"  align="center" justify="center">
                                <v-btn color="success" @click="copyPassWord">Copiar Palavra-Passe</v-btn>
                            </v-row>
                        </v-container>
                    </fieldset>
                </v-card-text>
            </v-card>
        </v-dialog>


    </v-row>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props: {
        item: {type: Object}
    },
    data: () => ({
        STATUS_CODE: Object.freeze({
            ACCEPTED: 'ACCEPTED',
            REJECTED: 'REJECTED',
        }),
        reviewStatus: null,
        messages: [],
        submiting: false,
        viewProjectDialog: false,
    }),

    computed: {
        user() {
            if (this.$user.isAuthenticated)
                return this.$user.info;
            return null;
        },
    },

    methods: {
        openProjectDialog() {
            this.viewProjectDialog = true;
        },
        closeProjectDialog() {
            this.viewProjectDialog = false;
        },

        copyPassWord() {
            alert('Nada por copiar');
        },

        ...mapActions('juryPanel/jury', ['submitReview']),

        submitRating(status, auxiliarReview) {
            //TODO verificar se escolheu todo criterio
            const valid = this.user != null || this.reviewStatus != 'Avaliado';

            if (!valid) {
                this.messages = [{
                    success: false, 
                    message: 'Não é possivel Avaliar no momento. Erro de permissão ou já foi avaliado.'
                }];
                return;
            }

            this.messages = [];
            this.submiting = true;
            this.submitReview({ 
                data: {
                    status: status, 
                    jury2: this.user.id, 
                    auxiliarReview: auxiliarReview,   
                } 
            }).then((res) => {
                if (res.success) {
                    this.reviewStatus = 'Avaliado';   
                }
                this.messages = res.messages;
            }).catch(() => {
                this.messages = [{success: false, message: 'Ocorreu um erro!'}];
            }).finally(() => {
                this.submiting = false;
            });
        }

    }
}
</script>